var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"mr-20"},[_vm._v(_vm._s(_vm.$t('jobPosition.createNewJob')))]),_c('b-button',{staticClass:"btn-icon rounded-circle",style:(_vm.colorPrimaryBtn),attrs:{"variant":"primary"},on:{"click":function($event){_vm.showJobModal = true}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1),_c('b-modal',{attrs:{"id":"createJobModal","centered":"","ok-only":"","title":_vm.$t('jobPosition.createNewJob'),"no-close-on-backdrop":"","ok-title":_vm.$t('buttons.save')},on:{"close":_vm.resetForm},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"secondary","pill":""},on:{"click":_vm.resetForm}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isLoadingCreate,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{staticStyle:{"margin-left":"17px"},style:(_vm.colorPrimaryBtn),attrs:{"variant":"primary","pill":""},on:{"click":_vm.saveJobData}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.showJobModal),callback:function ($$v) {_vm.showJobModal=$$v},expression:"showJobModal"}},[_c('validation-observer',{ref:"createJobRules"},[_c('b-form',{ref:"formRegister"},[(!_vm.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))?_c('b-form-group',[_c('b',[_vm._v(_vm._s(_vm.$t('corporate.club'))+": ")]),_vm._v(" "),_c('label',[_vm._v(_vm._s(_vm.clubInfo.club_name)+" ")])]):_vm._e(),_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('generic.name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('generic.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"text","placeholder":_vm.$t('jobPosition.enterJobPosition')},model:{value:(_vm.job.name),callback:function ($$v) {_vm.$set(_vm.job, "name", $$v)},expression:"job.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))?_c('b-form-group',[_c('label',{attrs:{"for":"club-country-id"}},[_vm._v(" "+_vm._s(_vm.$t('generic.country'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('generic.country')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-country-id","label":"name","options":_vm.countries,"reduce":function (country) { return country.id; },"placeholder":_vm.$t('generic.selectCountry')},model:{value:(_vm.options.country_id),callback:function ($$v) {_vm.$set(_vm.options, "country_id", $$v)},expression:"options.country_id"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,363630349)})],1):_vm._e(),(_vm.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))?_c('b-form-group',[_c('label',{attrs:{"for":"club-state-id"}},[_vm._v(" "+_vm._s(_vm.$t('generic.state'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('generic.state')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-state-id","label":"name","options":_vm.states,"disabled":_vm.options.country_id == null,"reduce":function (states) { return states.id; },"placeholder":_vm.$t('generic.selectState')},model:{value:(_vm.options.state_id),callback:function ($$v) {_vm.$set(_vm.options, "state_id", $$v)},expression:"options.state_id"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,922948993)})],1):_vm._e(),(_vm.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))?_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("corporate.club"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('corporate.club')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":_vm.$t('corporate.selectSportClub'),"label":"name","options":_vm.clubs,"disabled":_vm.options.state_id == null,"reduce":function (club) { return club.id; }},model:{value:(_vm.job.club_id),callback:function ($$v) {_vm.$set(_vm.job, "club_id", $$v)},expression:"job.club_id"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2264245191)})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }