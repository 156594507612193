import apiInstance from './index'
import queryParams from './queryParams'

export default {
  async getJobs(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`jobs${query}`)
  },
  async storeJob(data) {
    return await apiInstance.post('jobs', data)
  },
  async updateJob(job, data) {
    return await apiInstance.put(`jobs/${job}`, data)
  },
  async statusJob(job) {
    return await apiInstance.put(`jobs/status/${job}`)
  },
  async deleteJob(job) {
    return await apiInstance.delete(`jobs/${job}`)
  },
  async getJob(job, paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`jobs/${job}${query}`)
  },
}